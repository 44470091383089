.show {
    display: block;
}
.hide {
    display: none;
}
.right {
    float: right;
}
.form-group.checkboxTitle {
    margin-bottom: 0;
}
.checkboxTitle label {
    padding: 0;
    font-size: 21px;
    line-height: inherit;
    color: #333;
}
.checkboxTitle input {
    margin-top: 10px;
}
.btn-vacasa {
    background-color: #538d9c !important;
    border-color: #538d9c !important;
    color: #ffffff !important;
}
.btn-vacasa:not(:disabled):not(.disabled).active:focus,
.btn-vacasa:not(:disabled):not(.disabled):active:focus,
.show > .btn-vacasa.dropdown-toggle:focus,
.btn-vacasa:focus {
    box-shadow: 0 0 0 0.2rem #538d9c !important;
}
.container-custom {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
